<template>
  <section class="brandManage">
    <h2 class="title_page">计划任务管理</h2>
    <div class="main">
      <el-button @click="addTask" type="primary" class="addbtn">新增计划任务</el-button>
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" ref="mutipleTable"
                :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}" height="650">
        <el-table-column prop="name" label="计划任务名称" width="120" align="center"></el-table-column>
        <el-table-column prop="station_name" label="推送岗位（可多选）" width="240" align="center">
        </el-table-column>
        <el-table-column prop="user_name" label="推送人员（可多选）" align="center">
        </el-table-column>
        <el-table-column prop="task_duration" label="任务时效（分钟）" align="center">
        </el-table-column>
        <el-table-column prop="trigger_time" label="任务触发 " align="center" width="300">
        </el-table-column>
        <el-table-column prop="is_enable" label="状态" align="center">
          <template slot-scope="scope"> {{ scope.row.is_enable === 1 ? '启用' : '停用' }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editTask(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="新增计划任务" :visible.sync="taskshow" width='80%'>
      <div class="dialogbox">
        <el-form ref="form" :model="form" :rules="rules" label-width="110px" :inline="true">
          <el-row>
            <el-col :span="24">
              <el-form-item label="任务名称" prop="name">
                <el-input v-model="form.name" placeholder="必填，不超过20字" maxlength="20"
                          style="width:500px" required></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="推送岗位(多选)">
                <el-select v-model="form.checkStation" placeholder="请选择" multiple>
                  <el-option v-for="(item, index) in stationList" :key="index" :value="item.id"
                             :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="推送人员(多选)">
                <el-select v-model="form.checkUser" placeholder="请选择" multiple>
                  <el-option v-for="(item, index) in userList" :key="index" :value="item.user_id"
                             :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="任务时效(分钟)" prop="taskDuration">
                <!--                <el-input v-model="form.taskDuration" placeholder="必填，不超过20字" style="width:150px"-->
                <!--                          maxlength="20" required></el-input>-->
                <el-input-number v-model="form.taskDuration" :min="1" :max="255"
                                 label="描述文字"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="任务触发日期" prop="times">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.times"
                    type="daterange"
                    range-separator="-"
                    :picker-options="pickerOptions"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="每天" prop="triggerTime" label-width="50px">
                <el-time-picker
                    value-format="HH:mm"
                    v-model="form.triggerTime"
                    :picker-options="{
                                        selectableRange: '00:00:00 - 23:59:00'
                                    }"
                    placeholder="任意时间点">
                </el-time-picker>
                <!--                <el-input v-model="form.triggerTime" style="width: 60px"-->
                <!--                          required></el-input>-->
                <!--                                <el-input-number v-model="form.triggerTime" :min="0" :max="24"
                                                                 label="描述文字"></el-input-number>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="任务状态" prop="isEnable">
                <el-radio-group v-model="form.isEnable" required>
                  <el-radio label="1">启用</el-radio>
                  <el-radio label="0">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="任务类型" prop="taskType">
                <el-radio-group v-model="form.taskType" required>
                  <el-radio label="1">包厢任务</el-radio>
                  <el-radio label="2">非包厢任务</el-radio>
                  <el-radio label="3">没有明细</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="right"></el-divider>
          <el-row v-if="form.taskType&&form.taskType!=3">
            <el-col :span="24">
              <el-button type="success" @click="addDetailed">添加任务明细</el-button>
            </el-col>
          </el-row>
          <div class="detailed" v-for="(item,index) in detailedData" :key="index">
            <div v-if="form.taskType&&form.taskType!=3">
              <div>
                <el-row>
                  <el-col :span="1">
                    <div class="num">{{ index + 1 }}</div>
                  </el-col>
                  <el-col :span="14">
                    <el-input placeholder="填写任务明细，不超过500字" v-model="item.name"
                              maxlength="20"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <el-button type="danger" @click="delDetail(index)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
              <div>
                <el-row>
                  <el-col :span="1">
                    <div class="line">占位</div>
                  </el-col>
                  <el-col :span="16">
                    <el-col :span="3">
                      <div>是否检查</div>
                    </el-col>
                    <el-col :span="14">
                      <el-radio-group v-model="item.is_check">
                        <el-radio :label="1">检查(检查选项【合格】/【不合格】)</el-radio>
                        <el-radio :label="2">不检查</el-radio>
                      </el-radio-group>
                    </el-col>
                  </el-col>
                </el-row>
              </div>
              <div v-if="item.is_check!=2">
                <el-row>
                  <el-col :span="1">
                    <div class="line">占位</div>
                  </el-col>
                  <el-col :span="16">
                    <el-col :span="3">
                      <div>不合格操作</div>
                    </el-col>
                    <el-col :span="14">
                      <el-radio-group v-model="item.no_pass">
                        <el-radio :label="1">拍照&写备注</el-radio>
                        <el-radio :label="2">提交OA审批</el-radio>
                        <el-radio :label="3">不操作</el-radio>
                      </el-radio-group>
                    </el-col>
                  </el-col>
                </el-row>
              </div>
              <div v-if="item.no_pass=='2'">
                <el-row>
                  <el-col :span="1">
                    <div class="line">占位</div>
                  </el-col>
                  <el-col :span="16">
                    <el-col :span="3">
                      <div>OA页面地址</div>
                    </el-col>
                    <el-col :span="21">
                      <el-input placeholder="填写任务明细，不超过500字" v-model="item.oa_url"
                      ></el-input>
                    </el-col>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-form>
        <div class="btnbox">
          <el-button type="info" @click="taskshow = false">取消</el-button>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </div>
      </div>

    </el-dialog>
  </section>
</template>
<script>
// 
import {
  getStoreTaskDetail,
  planTaskList, saveStoreTask,
  selectStationList,
  selectUserList, updateStoreTask
} from "../../../api/srv-store";

export default {
  name: 'brandManage',
  data() {
    return {
      pickerOptions: { // 限制收货时间不让选择今天以前的
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 1000 * 60 * 60 * 24
        }
      },
      checkBrand: '',
      checkCompany: '',
      brandList: [],
      companyList: [],
      stationList: [],
      userList: [],
      taskshow: false,
      form: {},
      detailedData: [],
      tableData: [],
      rules: {
        name: [
          {required: true, message: '请输入任务名称', trigger: 'blur'}
        ],
        taskDuration: [
          {required: true, message: '请输入任务时效', trigger: 'blur'}
        ],
        times: [
          {required: true, message: '请选择任务触发日期', trigger: 'change'}
        ],
        triggerTime: [
          {required: true, message: '请选择触发时间点（24小时制）', trigger: 'blur'}
        ],
        isEnable: [
          {required: true, message: '请选择任务启用状态', trigger: 'change'}
        ],
        taskType: [
          {required: true, message: '请选择任务类型', trigger: 'change'}
        ],
      },
    }
  },
  created() {
    this.checkBrand = JSON.parse(localStorage.getItem('brandId'))
    this.checkCompany = JSON.parse(localStorage.getItem('storesId'))
    this.getPlanList()
  },

  methods: {
    //获取岗位列表
    getStationList() {
      selectStationList({
        brand_id: this.checkBrand,
        store_id: this.checkCompany
      }).then(Er => {
        if (Er.return_code === 200) {
          this.stationList = Er.data
        }
      })
    },

    //获取员工列表
    getUserList() {
      selectUserList({
        brand_id: this.checkBrand,
        store_id: this.checkCompany
      }).then(Er => {
        if (Er.return_code === 200) {
          this.userList = Er.data
        }
      })
    },

    /**获取门店计划任务配置列表*/
    getPlanList() {
      // 全局取品牌id 和店铺id
      planTaskList({
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
      }).then(Er => {
        if (Er.return_code === 200) {
          this.tableData = Er.data;
        }
      })
    },

    /**新增计划任务弹窗*/
    addTask() {
      this.getStationList()
      this.getUserList()
      if (this.$refs['form'] !== undefined) {
        this.$refs['form'].resetFields()
      }
      this.form = {isEnable: "1", taskType: "1"}
      this.detailedData = []
      this.taskshow = true
    },

    /**编辑计划任务弹窗*/
    editTask(val) {
      this.getStationList()
      this.getUserList()
      this.form = {
        name: '',
        isEnable: '',
        checkStation: [],
        checkUser: [],
        taskDuration: '',
        times: [],
        triggerTime: '',
        taskType: ''
      }
      this.detailedData = []
      /**获取任务详情*/
      getStoreTaskDetail({
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
        task_plan_id: val,
      }).then(Er => {
        if (Er.return_code === 200) {
          this.form.times = [];
          this.form.checkStation = [];
          this.detailedData = []
          this.form.id = Er.data.id;
          this.form.name = Er.data.name;
          this.form.isEnable = Er.data.is_enable + '';
          Er.data.station_id.split(',').map(item => {
            if (item != '') {
              this.form.checkStation.push(parseInt(item))
            }
          });
          if (Er.data.user_id != '') {
            this.form.checkUser = Er.data.user_id.split(',');
          }
          this.form.taskDuration = Er.data.task_duration;
          this.form.times.push(Er.data.begin_date);
          this.form.times.push(Er.data.end_date);
          this.form.triggerTime = Er.data.trigger_time;
          this.form.taskType = Er.data.task_type + '';
          if (Er.data.store_task_plan_detail.length > 0) {
            Er.data.store_task_plan_detail.map(item => {
              let obj = {};
              obj.name = item.name
              obj.is_check = item.is_check
              obj.no_pass = item.no_pass
              obj.oa_url = item.oa_url
              this.detailedData.push(obj)
            })
          }
          this.taskshow = true
        }
      })
      if (this.$refs['form'] !== undefined) {
        this.$refs['form'].resetFields()
      }
    },

    /**添加任务明细*/
    addDetailed() {
      let obj = {
        name: '',
        is_check: 1,
        no_pass: 1,
        oa_url: '',
      }
      this.detailedData.push(obj)
    },

    /**删除*/
    delDetail(index) {
      this.detailedData.splice(index, 1);
    },

    /**保存*/
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //组合提交参数
          let newArr = {};
          newArr.task_plan_id = this.form.id;
          newArr.brand_id = this.checkBrand;
          newArr.store_id = this.checkCompany;
          newArr.name = this.form.name;
          newArr.is_enable = this.form.isEnable;
          newArr.station_id = this.form.checkStation ? this.form.checkStation.join(',') : '';
          newArr.user_id = this.form.checkUser ? this.form.checkUser.join(',') : '';
          newArr.task_duration = this.form.taskDuration;
          newArr.begin_date = this.form.times[0];
          newArr.end_date = this.form.times[1];
          newArr.trigger_time = this.form.triggerTime;
          newArr.task_type = this.form.taskType;
          newArr.detail = JSON.stringify(this.detailedData)
          if (!newArr.station_id && !newArr.user_id) {
            this.$message({
              type: 'warning',
              message: '请在推送岗位或者推送人员中选择至少一项'
            });
            return false
          }
          if (newArr.task_type != 3 && this.detailedData.length <= 0) {
            this.$message({
              type: 'warning',
              message: '请添加任务明细'
            });
            return false
          }

          if (this.form.id) {//编辑
            updateStoreTask(newArr).then(Er => {
              if (Er.return_code === 200) {
                this.$message({
                  type: 'success',
                  message: Er.return_msg
                });
                this.taskshow = false
                this.getPlanList()
              }
            })
          } else {//新增
            saveStoreTask(newArr).then(Er => {
              if (Er.return_code === 200) {
                this.$message({
                  type: 'success',
                  message: Er.return_msg
                });
                this.taskshow = false
                this.form = {}
                this.detailedData = []
                this.getPlanList()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.brandManage {
  min-height: 100%;
  background-color: #f7fafb;

  .title_page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .main {
    background-color: #ffffff;
    height: 740px;
    padding: 40px;
    padding-top: 50px;
    position: relative;

    .addbtn {
      z-index: 10;
      position: absolute;
      top: 5px;
      right: 50px;
    }

    /deep/ .el-input__inner {
      padding: 0 5px;
      height: 30px;

      &::placeholder {
        color: rgba(62, 86, 110, 0.2);
        font-size: 14px;
      }
    }

    .el-table {
      color: #3e566e;
      font-size: 14px;
    }
  }

  /deep/ .el-form-item__label {
    font-size: 12px;
  }

  /deep/ el-table {
    margin-top: 0;
  }

  /deep/ .el-divider--horizontal {
    margin-top: 0;
  }

  /deep/ .el-dialog__body::-webkit-scrollbar {
    display: none;
  }

  .dialogbox::-webkit-scrollbar {
    display: none;
  }

  .dialogbox {
    max-height: 640px;
    overflow-y: scroll;

    .btnbox {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .detailed {
      padding: 30px;

      > div {
        > div {
          padding-top: 20px;
        }
      }

      .num {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
        line-height: 36px;
        color: #ffffff;
        background: #43d97b;
      }

      .line {
        color: #ffffff;
      }

    }
  }
}
</style>